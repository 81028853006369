import React from "react";
import {getColour, getFadeInAnimation} from "../../utils/HelperFunctions";

interface SkillEntryProps {
    name: string,
    skillPercentage: number
    number: number
}

const SkillEntry: React.FC<SkillEntryProps> = ({name, skillPercentage, number}) => {
    const percentage: string = skillPercentage + '%'

    return (
        <div className="col-md-6 animate-box" data-animate-effect={getFadeInAnimation(number)}>
            <div className="progress-wrap">
                <h3>{name}</h3>
                <div className="progress">
                    <div className={"progress-bar " + getColour(number)} role="progressbar" aria-valuenow={skillPercentage}
                         aria-valuemin={0} aria-valuemax={100} style={{width:percentage}}>
                        <span>{percentage}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkillEntry
