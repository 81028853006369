import React from 'react'
import SkillEntry from "../components/SkillEntry/SkillEntry";

const Skills: React.FC = () => {
    return (
        <section className="colorlib-skills" data-section="skills">
            <div className="colorlib-narrow-content">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
                         data-animate-effect="fadeInLeft">
                        <span className="heading-meta">My Specialty</span>
                        <h2 className="colorlib-heading animate-box">My Skills</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 animate-box" data-animate-effect="fadeInLeft">
                        <p> Here are the various skill levels in programming languages I have acquired. They are scored on percentage proficiency, where 100% is complete knowledge.</p>
                    </div>
                    <SkillEntry name={'Java'} skillPercentage={85} number={0}/>
                    <SkillEntry name={'Kotlin'} skillPercentage={85} number={1}/>
                    <SkillEntry name={'Python'} skillPercentage={80} number={2}/>
                    <SkillEntry name={'C#'} skillPercentage={80} number={3}/>
                    <SkillEntry name={'TypeScript'} skillPercentage={75} number={4}/>
                    <SkillEntry name={'C'} skillPercentage={65} number={5}/>
                    <SkillEntry name={'Bash'} skillPercentage={60} number={6}/>
                    <SkillEntry name={'Haskell'} skillPercentage={60} number={7}/>
                    <SkillEntry name={'C++'} skillPercentage={50} number={8}/>
                    <SkillEntry name={'Go'} skillPercentage={50} number={9}/>
                    <SkillEntry name={'SQL'} skillPercentage={40} number={10}/>
                </div>
            </div>
        </section>
    )
}

export default Skills;
