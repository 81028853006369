import React from 'react'
import IntroductionSlide from "../components/IntroductionSlide/IntroductionSlide";

const Introduction: React.FC = () => {
    return (
        <div id={'Introduction'}>
            <section id="colorlib-hero" className="js-fullheight" data-section="home">
                <div className="flexslider js-fullheight">
                    <ul className="slides">
                        <IntroductionSlide titleFirstLine={"Hi"}
                                           titleSecondLine={"I'm Arjun"}
                                           link={{
                                               url: 'assets/CV.pdf',
                                               title: 'Download My CV',
                                           }}
                        />
                    </ul>
                </div>
            </section>
        </div>
    )
}

export default Introduction
