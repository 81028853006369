import React from "react";
import {getColour, getFadeInAnimation} from "../../utils/HelperFunctions";

interface ExperienceEntryProps {
    title: string,
    date: string,
    description: string,
    number: number
}

const ExperienceEntry: React.FC<ExperienceEntryProps> = ({title, date, description, number}) => {
    return (
        <article className="timeline-entry animate-box" data-animate-effect={getFadeInAnimation(number)}>
            <div className="timeline-entry-inner">
                <div className={"timeline-icon " + getColour(number)}>
                    <i className="icon-briefcase3"/>
                </div>
                <div className="timeline-label">
                    <h2>{title} <span>{date}</span></h2>
                    <p>{description}</p>
                </div>
            </div>
        </article>
    )
}

export default ExperienceEntry
