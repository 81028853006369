import * as firebase from "firebase";
import 'firebase/auth';

export class AuthService {
    getCurrentUser() {
        return firebase.auth().currentUser;
    }

    async signOutCurrentUser() {
        const user = this.getCurrentUser();
        if (user !== null) {
            return firebase.auth().signOut();
        }
    }

    async signInWithEmail(email: string, password: string) {
        return firebase.auth().signInWithEmailAndPassword(email, password);
    }

    onAuthStateChanged(obsFunc: firebase.Observer<any, Error> | ((a: firebase.User | null) => any)) {
        return firebase.auth().onAuthStateChanged(obsFunc);
    }
}
