import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css'
import * as serviceWorker from './serviceWorker';
// Firebase services
import * as firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/storage";
import {AuthService} from "./services/AuthService";
import {FirestoreService} from "./services/DatabaseService";
import {StorageService} from "./services/StorageService";

const firebaseConfig = {
    apiKey: "AIzaSyCqsomChmiph5g8pY9b_XSKo8ib8dZpdTk",
    authDomain: "personal-website-arjun.firebaseapp.com",
    databaseURL: "https://personal-website-arjun.firebaseio.com",
    projectId: "personal-website-arjun",
    storageBucket: "personal-website-arjun.appspot.com",
    messagingSenderId: "1065464657502",
    appId: "1:1065464657502:web:19f2164eb1a0abbb7b26cf",
    measurementId: "G-WGRJ752WHM"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const authService = new AuthService();
export const databaseService = new FirestoreService();
export const storageService = new StorageService();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
