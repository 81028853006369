import React from 'react';
/* Theme variables */
import './theme/variables.css';
import Sidebar from "./sections/Sidebar";
import Introduction from "./sections/Introduction";
import About from "./sections/About";
import Experience from "./sections/Experience";
import Projects from "./sections/Projects";
import Skills from "./sections/Skills";
import Education from "./sections/Education";
import FoodMap from "./sections/FoodMap";
import Certifications from "./sections/Certifications";

const App: React.FC = () => {
    return (
    <div id="colorlib-page">
        <div id="container-wrap">
            <Sidebar/>
            <div id="colorlib-main">
                <Introduction/>
                <About/>
                <Education/>
                <Experience/>
                {/*<Projects/>*/}
                <Certifications/>
                <Skills/>
                <FoodMap/>
            </div>
        </div>
    </div>
)};

export default App;
