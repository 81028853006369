import * as firebase from "firebase";
import 'firebase/firestore';

export class StorageService {
    storage = firebase.storage();

    getCVUrl(callback: (url: string) => void) {
        const cvRef = this.storage.ref('Arjun_CV.pdf')
        cvRef.getDownloadURL().then((url) => {
            callback(url as string)
        }).catch((e) => {
            console.log(e)
        })
    }

}
