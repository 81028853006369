import React from "react";

const Certifications: React.FC = () => {
    return (
        <section className="colorlib-contact" data-section="certifications">
            <div className="colorlib-narrow-content">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
                         data-animate-effect="fadeInLeft">
                        <span className="heading-meta">My Recognised Achievements</span>
                        <h2 className="colorlib-heading">Certifications</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 animate-box" data-animate-effect="fadeInLeft">
                        <a href={'https://www.credly.com/badges/ab80fddc-d192-4047-8f72-62040f602363/public_url'}
                           target="_blank"
                           rel="noopener noreferrer">
                            <img
                                src="https://images.credly.com/size/340x340/images/6a254dad-77e5-4e71-8049-94e5c7a15981/azure-fundamentals-600x600.png"
                                width="100%"
                                title={"Microsoft Certified: Azure Fundamentals"}
                                style={{border: 0, maxWidth: '300px'}}
                                alt={"Microsoft Certified: Azure Fundamentals"}/>
                        </a>
                    </div>
                    <div className="col-md-3 animate-box" data-animate-effect="fadeInLeft">
                        <a href={'https://www.credly.com/badges/536ee878-07fd-41c2-bed7-615a86a7c1da/public_url'}
                           target="_blank"
                           rel="noopener noreferrer">
                            <img
                                src="https://images.credly.com/size/340x340/images/4136ced8-75d5-4afb-8677-40b6236e2672/azure-ai-fundamentals-600x600.png"
                                width="100%"
                                title={"Microsoft Certified: Azure AI Fundamentals"}
                                style={{border: 0, maxWidth: '300px'}}
                                alt={"Microsoft Certified: Azure AI Fundamentals"}/>
                        </a>
                    </div>
                    <div className="col-md-3 animate-box" data-animate-effect="fadeInLeft">
                        <a href={'https://www.credly.com/badges/6b9cb6b4-a0ba-4195-966b-387b404bb12b/public_url'}
                           target="_blank"
                           rel="noopener noreferrer">
                            <img
                                src="https://images.credly.com/size/340x340/images/63316b60-f62d-4e51-aacc-c23cb850089c/azure-developer-associate-600x600.png"
                                width="100%"
                                title={"Microsoft Certified: Azure Developer Associate"}
                                style={{border: 0, maxWidth: '300px'}}
                                alt={"Microsoft Certified: Azure Developer Associate"}/>
                        </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 animate-box" data-animate-effect="fadeInLeft">
                        <a href={'https://www.credential.net/60b67f4b-3451-4d9f-837b-a1bdd8764291#'}
                           target="_blank"
                           rel="noopener noreferrer">
                            <img
                                src="https://api.accredible.com/v1/frontend/credential_website_embed_image/badge/13800970"
                                width="100%"
                                title={"Google Certified Associate Cloud Engineer"}
                                style={{border: 0, maxWidth: '300px'}}
                                alt={"Google Certified Associate Cloud Engineer"}/>
                        </a>
                    </div>
                    <div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
                        <a href={'https://coursera.org/share/f6cf9c073b73171892c7c7bf6ba73988'}
                           target="_blank"
                           rel="noopener noreferrer">
                            <img src="assets/MLStanford.png"
                                 width="100%"
                                 title={"Machine Learning Stanford Coursera Certificate"}
                                 style={{border: 0, maxWidth: '300px'}}
                                 alt={"Machine Learning Stanford Coursera Certificate"}/>
                        </a>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Certifications
