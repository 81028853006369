import React from 'react'
import ExperienceEntry from "../components/ExperienceEntry/ExperienceEntry";

const Experience: React.FC = () => {
    return (
        <div>
            <section className="colorlib-experience" data-section="experience">
                <div className="colorlib-narrow-content">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
                             data-animate-effect="fadeInLeft">
                            <span className="heading-meta">highlights</span>
                            <h2 className="colorlib-heading animate-box">Experience</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="timeline-centered">
                                <ExperienceEntry number={5}
                                                 title={"Palantir Technologies: Forward Deployed Engineer"}
                                                 date={"September 2022 - Present"}
                                                 description={"Currently, I am working on a project in the healthcare sector where I own the development of a data-driven solution that has been sold to multiple customers amongst other support tasks. The crux of my work involves building relationships with customers to decompose problems from various domains and communicate technical solutions."}
                                />
                                <ExperienceEntry number={1}
                                                 title={"About:Energy: DevOps Engineer"}
                                                 date={"May 2022 - September 2022"}
                                                 description={"Working towards developing internal tooling and data-driven SaaS products on AWS. Leading role in architecting cloud solutions to deploy onto AWS, utilising Infrastructure as Code (Terraform) extensively in CI/CD pipelines to easily manage AWS resources and automate deployments."}
                                />
                                <ExperienceEntry number={2}
                                                 title={"Microsoft: Commercial Software Engineering (Government): Software Engineer Intern"}
                                                 date={"April 2021 - September 2021"}
                                                 description={"In Commercial Software Engineering at Microsft, we work on the most complex customer problems and implement solutions with them on Azure. The developer crew I am currently working with, focus on customers in the government sector. So far I have worked on two customer projects: a message processing system and an AI powered data enrichment pipeline. This experience has vastly improved my client facing communication skills, solutions architecting, DevOps, MLOps and open source software contribution skills."}
                                />
                                <ExperienceEntry number={3}
                                                 title={"Imperial College London - Personal Programming Tutor"}
                                                 date={"October 2020 - March 2021"}
                                                 description={"As a Personal Programming Tutor, I will assist teaching first year Computing students about programming in Haskell, Kotlin and Java."}
                                />
                                <ExperienceEntry number={4}
                                                 title={"Imperial College London - Computing Departmental Wellbeing Representative"}
                                                 date={"June 2020 - June 2022"}
                                                 description={"My role is to oversee the wellbeing of the department of Computing’s students and organise the means necessary to support them. I will be working closely with the year representatives to ensure everyone can voice their concerns."}
                                />
                                <ExperienceEntry number={5}
                                                 title={"FundApps - Software Engineer Intern"}
                                                 date={"July 2020 - October 2020"}
                                                 description={"I am working as part of a team to transform Rapptr(.NET), FundApps' flagship service, in to a distributed and serverless architecture to optimise speed and cost. We are doing this by implementing a custom MapReduce by orchestrating various AWS services e.g. Fargate for serverless compute, provisioning with Terraform (and testing with Terratest) and deploying through Octopus."}
                                />
                                <ExperienceEntry number={1}
                                                 title={"Royal Brompton Hospital CMR Unit - Freelance Software Engineer"}
                                                 date={"March 2020 - October 2020"}
                                                 description={'I have developed a data analysis tool, in the form of a desktop application(Python,Pandas,PyQt5), for analysing results in a novel cardiac imaging technique: in vivo Diffusion Tensor Cardiac MR (DT-CMR) imaging. This tool is being used by medical researchers. Additionally, with a researcher I am exploring the use of Deep Learning(GANs) with the goal to optimise patient scan time. This is using Python and Tensorflow 2.0. We have submitted a paper for review.'}
                                />
                                <ExperienceEntry number={2}
                                                 title={"Schlumberger Information Solutions AS - Software Engineer Intern"}
                                                 date={"July 2019 - September 2019"}
                                                 description={'I built a commercial prototype UI, in Angular and Node.js, to automate a workflow in a next generation risk assessment web app; linking two products GeoX and Petrel for the first time in this way. I developed a calculation service - a REST ASP.NET Web API with user authorisation. This was deployed on Google App Engine and took 60% less time than the equivalent Petrel calculation. This prototype was demoed to Equinor, one of their largest clients.'}
                                />
                                <ExperienceEntry number={3}
                                                 title={"University of Leicester - Research Engineer"}
                                                 date={"September 2017"}
                                                 description={'Employed CAD software Siemens NX to design and test components of the HESSI satellite. Analysed thermodynamic and kinetic simulation data to structurally improve my CAD models. Engineered solutions to tasks using Arduino micro-controllers.'}
                                />
                                <ExperienceEntry number={4}
                                                 title={"DeMontfort University - Work Placement"}
                                                 date={"August 2016"}
                                                 description={'Developed software to calculate the annual power output of solar panel technologies using Python at their Institute of Energy and Sustainable development.'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Experience
