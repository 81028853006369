import * as firebase from "firebase/app";
import 'firebase/firestore';
import {Project} from "../data/Project";

export class FirestoreService {
    db = firebase.firestore();
    projectsRef = this.db.collection('projects');

    async getProjects(callback: (res: Project[]) => void) {
        let projects: Project[] = [];
        this.projectsRef.orderBy("date", "desc").get()
            .then((querySnapshot) => {
                querySnapshot.forEach((projectDoc) => {
                    let project = projectDoc.data() as Project;
                    project.date = (projectDoc.data().date as firebase.firestore.Timestamp).toDate()
                    projects.push(project)
                })
            }).finally(() => (callback(projects)))
    }
}
