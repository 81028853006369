import React from 'react'

const Sidebar: React.FC = () => {
    return (
        <div>
            <div>
                <nav className="js-colorlib-nav-toggle colorlib-nav-toggle" data-toggle="collapse"
                     data-target="#navbar" aria-expanded="false" aria-controls="navbar"><i/></nav>
                <aside id="colorlib-aside" className="border js-fullheight">
                    <div className="text-center">
                        <div className="author-img" style={{backgroundImage: 'url(images/about.jpeg)'}}/>
                        <h1 id="colorlib-logo"><a href="index.html">Arjun Banerjee</a></h1>
                    </div>
                    <nav id="colorlib-main-menu" role="navigation" className="navbar">
                        <div id="navbar" className="collapse">
                            <ul>
                                <li className="active"><a href="#home" data-nav-section="home">Introduction</a></li>
                                <li><a href="#about" data-nav-section="about">About</a></li>
                                <li><a href="#education" data-nav-section="education">Education</a></li>
                                <li><a href="#experience" data-nav-section="experience">Experience</a></li>
                                {/*<li><a href="#projects" data-nav-section="projects">Projects</a></li>*/}
                                <li><a href="#certifications" data-nav-section="certifications">Certifications</a></li>
                                <li><a href="#skills" data-nav-section="skills">Skills</a></li>
                                <li><a href="#foodMap" data-nav-section="foodMap">Food Map</a></li>
                            </ul>
                        </div>
                    </nav>
                    <nav id="colorlib-main-menu">
                        <ul>
                            <li><a href="mailto:arj.banerjee@yahoo.co.uk" target="_blank"
                                   rel="noopener noreferrer"><i className="icon-mail5"/></a></li>
                            <li><a href="https://www.linkedin.com/in/arjunbanerjee2103/" target="_blank"
                                   rel="noopener noreferrer"><i className="icon-linkedin2"/></a></li>
                            <li><a href="https://github.com/arj119" target="_blank" rel="noopener noreferrer"><i
                                className="icon-github"/></a></li>
                        </ul>
                    </nav>
                    <div className="colorlib-footer"/>
                </aside>
            </div>
        </div>
    )
}

export default Sidebar
