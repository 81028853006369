import React from "react";

interface EducationEntryProps {
    title: string,
    paragraphs: string[]
    number: number,
    bulletPoints?: string[]
}

const EducationEntry: React.FC<EducationEntryProps> = ({title, paragraphs, number, bulletPoints}) => {
    const id: string = "heading" + number
    const collapseAnchor: string = "collapse" + number
    return (
        <div className="panel panel-default">
            <div className="panel-heading" role="tab" id={id}>
                <h4 className="panel-title">
                    <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href={'#' + collapseAnchor}
                       aria-expanded={number === 0} aria-controls={collapseAnchor}>{title}
                    </a>
                </h4>
            </div>
            <div id={collapseAnchor} className="panel-collapse collapse" role="tabpanel"
                 aria-labelledby={id}>
                <div className="panel-body">
                    { paragraphs.map(p => {
                        return <div className="row">
                            <div className="col-md-12">
                                {p}
                            </div>
                        </div>
                    })
                    }
                    <div className="row">
                        <div className="col-md-12">
                        </div>
                        {bulletPoints &&
                        <ul>
                            {bulletPoints.map((point, i) => <li key={i}>{point}</li>)}
                        </ul>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EducationEntry
