import React from "react";

export interface Link {
    title: string;
    url: string;
    icon?: string;
}

interface IntroductionSlideProps {
    backgroundImageUrl?: string;
    titleFirstLine: string;
    titleSecondLine?: string;
    link?: Link;
}

const IntroductionSlide: React.FC<IntroductionSlideProps> = ({backgroundImageUrl, titleFirstLine, titleSecondLine, link}) => {
    const backgroundImage: string = backgroundImageUrl ? backgroundImageUrl : 'images/arjunBergen2.png'

    function getCoverImageStyle(url: string) {
        return {
            backgroundImage: 'linear-gradient(to right, rgba(121, 121, 121, 0.4), rgba(0, 0, 0, 0.0)), url(' + url + ')',
        }
    }

    return (
        <li style={getCoverImageStyle(backgroundImage)}>
            <div className="overlay"/>
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                        <div className="slider-text-inner">
                            <div className="desc">
                                <h1>{titleFirstLine} {titleSecondLine && <>
                                    <br/> {titleSecondLine} </>}</h1>
                                {link && <p><a className="btn btn-primary btn-learn" style={{color: "greenyellow"}}
                                               href={link.url} target="_blank"
                                               rel="noopener noreferrer">{link.title} {link.icon && <i className={link.icon}/>} </a>
                                </p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}

export default IntroductionSlide
