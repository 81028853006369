
const fadeAnimations = ["fadeInTop", "fadeInLeft", "fadeInBottom", "fadeInRight"]

export function getFadeInAnimation(key: number): string {
    return fadeAnimations[key % fadeAnimations.length]
}

export function getColour(key: number): string {
    return "color-" + (key % 7 + 1)
}
