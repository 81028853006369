import React from 'react'

const About: React.FC = () => {
    return (<div>
            <section className="colorlib-about" data-section="about">
                <div className="colorlib-narrow-content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                                <div className="col-md-12">
                                    <div className="about-desc">
                                        <span className="heading-meta">About</span>
                                        <h2 className="colorlib-heading">Who Am I?</h2>
                                        <p>
                                            I have recently graduated from Computing at Imperial College London.
                                            Having a curious nature, I am always looking for interesting projects or roles that I can learn new
                                            skills from. If you have a new and fascinating opportunities please
                                            feel free to get in touch with me, I would love to hear about them. From
                                            my experiences thus far, I have built a keen interest in customer facing projects with real impact,
                                            particularly in the application of machine learning in production. I have an affinity for taking on
                                            DevOps responsibilities so a new field I am exploring is MLOps!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="colorlib-about">
                <div className="colorlib-narrow-content">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
                             data-animate-effect="fadeInLeft">
                            <span className="heading-meta">What I do?</span>
                            <h2 className="colorlib-heading">Here are some of my expertise</h2>
                        </div>
                    </div>
                    <div className="row row-pt-md">
                        <div className="col-md-4 text-center animate-box">
                            <div className="services color-3">
                                <span className="icon">
                                    <i className="icon-lightbulb"/>
                                </span>
                                <div className="desc">
                                    <h3>Machine Learning</h3>
                                    <p>
                                        After completing the Machine Learning course by Stanford University, courses at university,
                                        undertaking a research project using Deep Learning for medical imaging and production application in internships.
                                        I have explored and used <strong>Tensorflow</strong>, <strong>PyTorch</strong>, <strong>Python</strong>, <strong>Scikit-learn</strong>, <strong>Pandas</strong>, <strong>Dask/Vaex</strong> and <strong>MATLAB</strong>.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-center animate-box">
                            <div className="services color-5">
                                <span className="icon">
                                    <i className="icon-cloud4"/>
                                </span>
                                <div className="desc">
                                    <h3>Infrastructure</h3>
                                    <p>
                                        In my experience I have come across quite a few infrastructure solutions. In
                                        particular using <strong>Microsoft Azure</strong>, <strong>AWS</strong>,
                                        <strong>Google Cloud Platform</strong>, <strong>Digital
                                        Ocean</strong>, <strong>Firebase</strong>, <strong>Heroku</strong> and <strong>Terraform</strong>.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 text-center animate-box">
                            <div className="services color-6">
                                <span className="icon">
                                    <i className="icon-data"/>
                                </span>
                                <div className="desc">
                                    <h3>Databases</h3>
                                    <p>
                                        In taking Databases and currently Advanced Databases at Imperial College London,
                                        as well as through my roles and projects, I have used and learnt about:
                                        <strong>NoSQL</strong> databases such as <strong>Google Firestore</strong>,
                                         <strong>AWS DynamoDB</strong>, <strong>Azure CosmosDB</strong> and <strong>SQL</strong> databases such as <strong>Microsoft
                                        SQL Server</strong>.
                                    </p>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="row row-pt-md">

                        <div className="col-md-4 text-center animate-box">
                            <div className="services color-2">
                                <span className="icon">
                                    <i className="icon-cog-outline"/>
                                </span>
                                <div className="desc">
                                    <h3>Deployment</h3>
                                    <p> Through various projects and roles, I have learnt about the following deployment
                                        technologies: <strong>GitLab CI/CD</strong>, <strong>Github
                                            Actions</strong>, <strong>Azure DevOps</strong> and <strong>Docker (and
                                            Docker Compose)</strong>.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 text-center animate-box">
                            <div className="services color-4">
                                <span className="icon">
                                    <i className="icon-code"/>
                                </span>
                                <div className="desc">
                                    <h3>RESTful API</h3>
                                    <p>In many of my projects, there was a need for RESTful APIs for communication
                                        between front and backend.
                                        Thus I have had the chance to explore a variety of technologies: <strong>Springboot</strong>, <strong>Node.js</strong>, <strong>Python
                                            Flask</strong> and <strong>ASP.NET</strong>.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-center animate-box">
                            <div className="services color-1">
                                <span className="icon">
                                    <i className="icon-device-desktop"/>
                                </span>
                                <div className="desc">
                                    <h3>Web Development </h3>
                                    <p>I have experience building web apps
                                        using <strong>TypeScript</strong>, <strong>JavaScript</strong>, <strong>React</strong>, <strong>Angular</strong>, <strong>HTML</strong> and <strong>CSS</strong>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-pt-md">
                        <div className="col-md-4 text-center animate-box">
                            <div className="services color-6">
                                <span className="icon">
                                    <i className="icon-compass"/>
                                </span>
                                <div className="desc">
                                    <h3>Project Management</h3>
                                    <p>
                                        Luckily, through a variety of projects, I have been able to lead and manage
                                        small groups of developers to complete them. As a team we implemented
                                        <strong> Agile</strong> practices such
                                        as <strong>XP</strong>, <strong>Scrum</strong> and <strong>Kanban</strong> -
                                        combining where necessary. This
                                        has led me to improve my <strong>interpersonal communication</strong>, <strong>time
                                        management</strong> and <strong>leadership</strong> skills.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About
