import React from "react";

const FoodMap: React.FC = () => {
    return (
        <section className="colorlib-contact" data-section="foodMap">
            <div className="colorlib-narrow-content">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
                         data-animate-effect="fadeInLeft">
                        <span className="heading-meta">My Foodie Recommendations</span>
                        <h2 className="colorlib-heading">Food Map</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 animate-box" data-animate-effect="fadeInLeft">
                        <iframe src="https://www.google.com/maps/d/embed?mid=11EH7P5ZO-TcWptBBJaC8vIL4NAT0Nvhv&hl=en"
                                width="100%"
                                height="640px"
                                title={"Arjun's Food Map"}
                                style={{border:0}}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FoodMap
