import React, {useEffect} from "react"
import EducationEntry from "../components/EducationEntry/EducationEntry";
import {useIonViewDidEnter} from "@ionic/react";

const Education: React.FC = () => {
    return (
        <section className="colorlib-education" data-section="education">
            <div className="colorlib-narrow-content">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
                         data-animate-effect="fadeInLeft">
                        <span className="heading-meta">Education</span>
                        <h2 className="colorlib-heading animate-box">Education</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 animate-box" data-animate-effect="fadeInLeft">
                        <div className="fancy-collapse-panel">
                            <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                <div className="panel panel-default">
                                    <EducationEntry title={'Computing (Artificial Intelligence and Machine Learning) MEng - First Class Honours'} number={0} paragraphs={[
                                        'I have graduated from Imperial College London with a first class honours and an overall grade of 81.01%',
                                        'In particular, I am proud to say that my thesis, titled: \"FedGDKD: Federated GAN-Based Data-Free\n' +
                                        'Knowledge Distillation for Heterogeneous Models\" was awarded the Microsoft Research Prize and achieved a grade of 92.0%! Additionally,' +
                                        'through my efforts, I was awarded the following prizes:',
                                        'Firstly, the Dean\' List (years 3 and 4) for performing in the top 10% of students. Secondly, the ' +
                                        'Corporate Partnership Programme Group Project Prize in Computing Science for an outstanding third-year group project.' +
                                        'Thirdly, the IBM Second Year Group Project Prize for the best project in the \"Designing for Real People Module\"' +
                                        'Finally, the Well-being award, a new award that was created this year, for my contributions to the student experience as Well-being Departmental Representative.'
                                        ]
                                    }/>
                                    <EducationEntry title={'A Levels'} number={1} paragraphs={[
                                        'I continued my education at The Beauchamp College taking Further Mathematics A*, Mathematics A*, Physics A*, Chemistry A* and an Extended Project Qualification(EPQ) A*.' +
                                        'I created a mobile game for my EPQ. ']
                                    }/>
                                    <EducationEntry title={'GCSEs'} number={2} paragraphs={[
                                        'I attended The Beauchamp College for my GCSEs and had attained: 1 A^ in Further Mathematics, 9 A*s including Mathematics, English Language and Sciences, 2 As and 1 B.'
                                    ]}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Education
